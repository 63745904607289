import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root"
})
export class JwtService {
  private token: string;
  private helper: JwtHelperService;

  constructor() {
    this.helper = new JwtHelperService();
    this.token =
      localStorage.getItem("auth_jwt_token") === null
        ? null
        : localStorage.getItem("auth_jwt_token");
  }

  getToken(): string {
    return this.token;
  }
  setToken(token: string): void {
    localStorage.setItem("auth_jwt_token", token);
    this.token = token;
  }
  getPayload(): any {
    return this.helper.decodeToken(this.token);
  }
  getExpirationDate(): Date {
    return this.helper.getTokenExpirationDate(this.token);
  }
  isExpired(): boolean {
    try {
      return this.helper.isTokenExpired(this.token);
    } catch (e) {
      return true;
    }
  }
  isTokenActive(): boolean {
    if (this.token) return true;
    return false;
  }
  isTokenInLocalStorage(): boolean {
    if (localStorage.getItem("auth_jwt_token")) return true;
    return false;
  }
  removeToken(): void {
    this.token = null;
    localStorage.clear();
  }
}
