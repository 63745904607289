import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = localStorage.getItem("auth_jwt_token");
    if (token) {
      const modifiedRequest = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
      return next
        .handle(modifiedRequest)
        .pipe(catchError((x) => this.handleAuthError(x)));
    } else {
      return next.handle(request);
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.router.navigateByUrl("/dashboard/401");
      return of(err.message);
    }
    return throwError(err);
  }
}
