import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "../service/auth.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-email-verifivation",
  templateUrl: "./email-verifivation.component.html",
  styleUrls: ["./email-verifivation.component.css"],
})
export class EmailVerifivationComponent implements OnInit {
  logo: string = environment.logo;
  logo_width: number = environment.logo_width;
  isLoading: boolean = false;
  emailVerificationForm: FormGroup;

  error = null;
  verificationResponse = null;

  email: string = null;
  token: string = null;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.email = params["email"];
      this.token = params["token"];
    });

    this.emailVerificationForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      token: new FormControl(null, [Validators.required]),
    });

    this.autoValidate();
  }

  autoValidate() {
    if (this.email && this.token) {
      this.emailVerificationForm.setValue({
        email: this.email,
        token: this.token,
      });

      this.onSubmit();
    }
  }

  onSubmit() {
    this.isLoading = true;

    let formData: { email: string; token: string } = {
      email: this.emailVerificationForm.value.email,
      token: this.emailVerificationForm.value.token,
    };

    this.auth.emailVerification(formData).subscribe(
      (response: { request_status: boolean; error_message?: string }) => {
        this.verificationResponse = response;
        this.error = null;
        this.isLoading = false;

        if (response.request_status) {
          this.isLoading = true;
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 3000);
        }
      },
      (error) => {
        this.error = error;
        this.verificationResponse = null;
        this.isLoading = false;
      }
    );
  }
}
