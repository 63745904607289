const production: boolean = true;

export const environment = {
  production: production,
  logo: "/assets/images/logo.png",
  endpoint: "https://dcfx-api.tech4broker.in/ib1",
  ib_level: 1,
  client_portal_url: "https://portal.dynamic-capital.net",
  portal_name: "IB",
  logo_width: 200,
  inner_logo_width: 150,
};