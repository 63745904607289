import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "../service/auth.service";
import { Country, RegisterRequestData } from "../models/interface.model";
import { environment } from "../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  level: number = null;
  code: string = null;

  registrationForm: FormGroup;
  countryList: Country[] = null;
  logo: string = environment.logo;
  logo_width: number = environment.logo_width;
  isPasswordMatch: boolean = false;
  isLoading: boolean = false;
  passwordTypeToggle: boolean = true;

  passwordOnFocus: boolean = false;
  passwordStrengthStep1: boolean = false;
  passwordStrengthStep2: boolean = false;
  passwordStrengthStep3: boolean = false;

  error = null;
  registerResponse = null;
  showRegForm: boolean = true;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.code = params["code"];
      this.level = params["level"];
    });

    this.registrationForm = new FormGroup({
      fullname: new FormControl("", [
        Validators.required,
        this.isValidName.bind(this),
      ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      country: new FormControl(null, [Validators.required]),
      dialcode: new FormControl(null, [Validators.required]),
      mobile_number: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      confirm_password: new FormControl(null, [Validators.required]),
    });

    this.auth.getCountryList().subscribe((countries) => {
      this.countryList = countries;
    });

    this.registrationForm
      .get("country")
      .valueChanges.subscribe((value: Country) => {
        if (value)
          this.registrationForm.get("dialcode").setValue(value.dial_code);
      });

    this.registrationForm
      .get("password")
      .valueChanges.subscribe((value: string) => {
        if (value === null) {
          value = "";
        }

        if (value.length >= 8) {
          this.passwordStrengthStep1 = true;
        } else {
          this.passwordStrengthStep1 = false;
        }

        if (value.match(/[A-z]/) && value.match(/[\d]/)) {
          this.passwordStrengthStep2 = true;
        } else {
          this.passwordStrengthStep2 = false;
        }

        if (value.match(/[-!$%^@&*()_+|~=`{}\[\]:";'<>?,.\/]/)) {
          this.passwordStrengthStep3 = true;
        } else {
          this.passwordStrengthStep3 = false;
        }
      });

    this.registrationForm
      .get("confirm_password")
      .valueChanges.subscribe((value: string) => {
        if (value === this.registrationForm.get("password").value) {
          this.isPasswordMatch = true;
        } else {
          this.isPasswordMatch = false;
        }
      });

    this.registrationForm
      .get("password")
      .valueChanges.subscribe((value: string) => {
        if (value === this.registrationForm.get("confirm_password").value) {
          this.isPasswordMatch = true;
        } else {
          this.isPasswordMatch = false;
        }
      });
  }

  isValidName(control: FormControl): { [s: string]: boolean } {
    if (control.value) {
      let name = control.value.replace(/\s+/, "");
      if (name.length === 0) {
        return { isValidName: false };
      }
    }
    return null;
  }

  onSubmit() {
    this.isLoading = true;
    let formData: RegisterRequestData = {
      email: this.registrationForm.value.email,
      password: this.registrationForm.value.password,
      fullname: this.registrationForm.value.fullname,
      country: this.registrationForm.value.country.name,
      dialCode: this.registrationForm.value.country.dial_code,
      mobileNumber: this.registrationForm.value.mobile_number.toString(),
      refCode: this.code || null,
      refCodeLevel: this.level || null,
    };

    this.auth.register(formData).subscribe(
      (response: { request_status: boolean; error_message?: string }) => {
        this.registerResponse = response;
        this.error = null;

        if (response.request_status) {
          // this.showRegForm = false;
          this.registrationForm.reset();
          setTimeout(() => {
            this.isLoading = false;
            this.router.navigate(["/login"]);
          }, 3000);
        }
        this.isLoading = false;
      },
      (error) => {
        this.error = error;
        this.registerResponse = null;
        this.isLoading = false;
      }
    );
  }

  togglePasswordShow() {
    this.passwordTypeToggle = !this.passwordTypeToggle;
  }
}
