import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../service/auth.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  logo: string = environment.logo;
  logo_width: number = environment.logo_width;
  isPasswordMatch: boolean = false;

  isLoading: boolean = false;
  error = null;
  resetResponse = null;
  password_type_toggle: boolean = true;

  email: string = null;
  token: string = null;

  passwordOnFocus: boolean = false;
  passwordStrengthStep1: boolean = false;
  passwordStrengthStep2: boolean = false;
  passwordStrengthStep3: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.email = this.route.snapshot.queryParams["email"];
    this.token = this.route.snapshot.queryParams["token"];

    this.resetPasswordForm = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      confirm_password: new FormControl(null, [Validators.required])
    });

    this.resetPasswordForm
      .get("password")
      .valueChanges.subscribe((value: string) => {
        if (value === null) {
          value = "";
        }

        if (value.length >= 8) {
          this.passwordStrengthStep1 = true;
        } else {
          this.passwordStrengthStep1 = false;
        }

        if (value.match(/[A-z]/) && value.match(/[\d]/)) {
          this.passwordStrengthStep2 = true;
        } else {
          this.passwordStrengthStep2 = false;
        }

        if (value.match(/[-!$%^@&*()_+|~=`{}\[\]:";'<>?,.\/]/)) {
          this.passwordStrengthStep3 = true;
        } else {
          this.passwordStrengthStep3 = false;
        }
      });

    this.resetPasswordForm
      .get("confirm_password")
      .valueChanges.subscribe((value: string) => {
        if (value === this.resetPasswordForm.get("password").value) {
          this.isPasswordMatch = true;
        } else {
          this.isPasswordMatch = false;
        }
      });

    this.resetPasswordForm
      .get("password")
      .valueChanges.subscribe((value: string) => {
        if (value === this.resetPasswordForm.get("confirm_password").value) {
          this.isPasswordMatch = true;
        } else {
          this.isPasswordMatch = false;
        }
      });
  }

  onSubmit() {
    this.isLoading = true;

    let formData: { email: string; token: string; password: string } = {
      email: this.email,
      token: this.token,
      password: this.resetPasswordForm.value.password
    };

    this.auth.resetPassword(formData).subscribe(
      (response: { request_status: boolean; error_message?: string }) => {
        this.resetResponse = response;
        this.error = null;
        this.isLoading = false;

        if (response.request_status) {
          this.isLoading = true;
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 3000);
        }
      },
      error => {
        this.error = error;
        this.resetResponse = null;
        this.isLoading = false;
      }
    );
  }

  togglePasswordShow() {
    this.password_type_toggle = !this.password_type_toggle;
  }
}
